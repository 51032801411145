import React from 'react'
import Navigation from '../components/navigation/navigation'
import Footer from '../components/footer/footer'

import SEO from '../components/seo'

import mastheadimg from "../images/home/pageheader.svg" // Tell Webpack this JS file uses this image

import './principles.scss'

const Principles = () => (
  <Navigation>
    <SEO title="PlanGrid Design Experience Principles" keywords={[`plangrid`, `design`, `plangrid design`, ]} />
    <div className="pageheader">
      <div className="pagebody">
        <h2>Our Experience Principles</h2>
      </div>
      <div className="image">
        <img src={mastheadimg} alt="Logo" />
      </div>
    </div>
    <div className="pagebody">
      <h3>We always respect the context</h3>
      <p>The Field and Office are complementary, not identical experiences.
      We consider experiences end-to-end to ensure PlanGrid is simple and
      fast in the field, and powerful and customizable in the office.</p>
      <h3>We promote transparency while respecting privacy</h3>
      <p>We believe that transparency of information enables better
      communication and efficiency for teams. By allowing organizations
      additional control over their privacy and visibility settings,
      customers trust that their data is safe in our hands.</p>
      <h3>Be a trusted partner</h3>
      <p>PlanGrid isn’t just a piece of software, we’re a trusted partner to
      our customers in every project. We provide dependable and durable tools,
      and information to help our customers get the job done—and look
      professional while doing it.</p>
      <h3>We make it safe to enter the unknown</h3>
      <p>PlanGrid feels safe to explore and navigate so customers can get the
      most utility from our product. We provide the right tools and resources
      to support our customers – even when mistakes are made, recovery is easy.</p>
      <h3>PlanGrid adapts to every customer</h3>
      <p>No two organizations, projects, or people are alike, yet PlanGrid
      always feels “right for me”. We configure PlanGrid to show the right
      information at the right time, for the right audience, in a way that
      is accessible. We’re thoughtful when adding customization to avoid
      overwhelming our customers with options; especially those that result
      in negative consequences.</p>
      <h3>We anticipate our customers’ needs</h3>
      <p>We give our customers the information they need before they know
      they need it. Using the construction graph, we connect related
      information, automate time-consuming tasks, and provide actionable next steps.</p>
    </div>
    <Footer />
  </Navigation>
)

export default Principles
